import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, FormControlLabel, Checkbox } from '@mui/material';

const MedicamentForm = ({ medicament, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    dci: '',
    specialite: '',
    forme: '',
    dosage: '',
    qt_boite: '',
    prix: '',
    barcode: '',
    art_code: '',
    photo: '',
    masked: false,
    isFavourite: false,
  });

  const [photoFile, setPhotoFile] = useState(null);
  const [showFileInput, setShowFileInput] = useState(false);

  useEffect(() => {
    if (medicament) {
      setFormData(medicament);
    }
  }, [medicament]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    setPhotoFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    console.log('HERE')
    e.preventDefault();
    try {
      let uploadedPhotoUrl = formData.photo;

      // Handle photo upload
      if (photoFile) {
        const formDataUpload = new FormData();
        formDataUpload.append('photo', photoFile);
        formDataUpload.append('specialite', formData.specialite);
        formDataUpload.append('forme', formData.forme);
        formDataUpload.append('dosage', formData.dosage);
        formDataUpload.append('qt_boite', formData.qt_boite);

        const uploadRes = await axios.post('/upload', formDataUpload, {
          headers: {
            'Content-Type': 'multipart/form-data',
            "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token'),
          },
        });
        uploadedPhotoUrl = uploadRes.data.photoUrl;
      }

      const updatedFormData = { ...formData, photo: uploadedPhotoUrl };

      if (medicament) {
        await axios.put(`medicament/${medicament._id}`, updatedFormData, {
          headers: { "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token') },
        });
      } else {
        await axios.post('medicament', updatedFormData, {
          headers: { "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token') },
        });
      }
      onSave();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField label="DCI" name="dci" value={formData.dci} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Specialite" name="specialite" value={formData.specialite} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Forme" name="forme" value={formData.forme} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Dosage" name="dosage" value={formData.dosage} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Qt Boite" name="qt_boite" value={formData.qt_boite} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Prix" name="prix" value={formData.prix} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Barcode" name="barcode" value={formData.barcode} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Art Code" name="art_code" value={formData.art_code} onChange={handleChange} fullWidth margin="normal" />

      {formData.photo && !showFileInput ? (
        <div>
          <img src={formData.photo} alt="Current Medicament" style={{ maxWidth: '100%', marginBottom: '10px' }} />
          <Button variant="outlined" onClick={() => setShowFileInput(true)}>
            Change Photo
          </Button>
        </div>
      ) : (
        <input type="file" onChange={handleFileChange} />
      )}

      <FormControlLabel
        control={<Checkbox checked={formData.masked} onChange={handleChange} name="masked" />}
        label="Masked"
      />
      <FormControlLabel
        control={<Checkbox checked={formData.isFavourite} onChange={handleChange} name="isFavourite" />}
        label="Is Favourite"
      />
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};

export default MedicamentForm;
