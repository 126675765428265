import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { styled } from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import MailIcon from '@mui/icons-material/Mail';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

const openedMixin = (theme) => ({
    width: 300,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });
  
  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

function Sidebar() {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const MuiDrawer = styled(Drawer, {
        shouldForwardProp: (prop) => prop !== "open",
      })(({ theme, open }) => ({
        width: 300,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
          ...openedMixin(theme),
          "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": closedMixin(theme),
        }),
      }));
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <div>
            <IconButton edge="start" color="inherit" onClick={toggleDrawer(true)} aria-label="menu">
                <MenuIcon />
            </IconButton>

            <Drawer open={drawerOpen} onClose={toggleDrawer(false)} style={{ width: 1200 }}>
                <div role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                <List>
                    <ListItem button key="Dashboard" component={Link} to="/">
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem button key="Liste des médicaments" component={Link} to="/medicaments">
                        <ListItemIcon><InfoIcon /></ListItemIcon>
                        <ListItemText primary="Liste des médicaments" />
                    </ListItem>
                    <ListItem button key="Liste des interactions" component={Link} to="/interactions">
                        <ListItemIcon><InfoIcon /></ListItemIcon>
                        <ListItemText primary="Liste des interactions" />
                    </ListItem>
                    <ListItem button key="Liste des médicaments PCT" component={Link} to="/pct_meds">
                        <ListItemIcon><InfoIcon /></ListItemIcon>
                        <ListItemText primary="Liste des médicaments PCT" />
                    </ListItem>
                    <ListItem button key="Suggestion de médicaments" component={Link} to="/suggestions">
                        <ListItemIcon><InfoIcon /></ListItemIcon>
                        <ListItemText primary="Suggestion de médicaments" />
                    </ListItem>
                    <ListItem button key="Problèmes reportés" component={Link} to="/Problems">
                        <ListItemIcon><InfoIcon /></ListItemIcon>
                        <ListItemText primary="problèmes reportés" />
                    </ListItem>
                    <ListItem button key="Pathologies" component={Link} to="/pathologies">
                        <ListItemIcon><CoronavirusIcon /></ListItemIcon>
                        <ListItemText primary="Pathologies" />
                    </ListItem>
                </List>
                </div>
            </Drawer>
        </div>
    );
}

export default Sidebar;
