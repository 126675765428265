import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Authenticated from './routes/Authenticated';
import Unauthenticated from './routes/Unauthenticated';

axios.defaults.baseURL = 'https://mvp.saydalid.com/api/';

function App() {
  const token = sessionStorage.getItem('token');
  return (
    <Router>
        <Routes>
        <Route
            path="*"
            element={token ? <Authenticated /> : <Unauthenticated />}
          />
        </Routes>
    </Router>
  );
}

export default App;
