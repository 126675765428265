import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const MedicamentVariantForm = ({ variant, medicamentId, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    medoc_ou_complement_ou_phyto: '',
    dose: '',
    frequence: '',
    duree_traitement: '',
    conseil_utilisation: '',
    tableau_ou_pas: '',
    age_specifique_medic: '',
    min_age_enfant: '',
    sexe_specifique_medic: '',
    femme_enceinte: '',
    allaitement: '',
    hta: '',
    diabete: '',
    tags: '',
    legal_class: '',
    entry_type: '',
    rules: '',
    traitement_appoint: '',
    medicamentId: medicamentId,
    pathologyId: '',
  });

  const [pathologies, setPathologies] = useState([]);

  useEffect(() => {
    if (variant) {
      setFormData(variant);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        medicamentId: medicamentId,
      }));
    }

    // Fetch pathologies
    const fetchPathologies = async () => {
      try {
        const response = await axios.get('pathologies', {
            headers: { "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token')},
          });;
        setPathologies(response.data);
      } catch (error) {
        console.error('Error fetching pathologies:', error);
      }
    };

    fetchPathologies();
  }, [variant, medicamentId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (variant) {
        await axios.put(`medicament_variants/${variant._id}`, formData, {
            headers: { "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token')},
          });
      } else {
        await axios.post('medicament_variants', formData, {
        headers: { "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token')},
      });
      }
      onSave();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField label="Medoc ou Complement ou Phyto" name="medoc_ou_complement_ou_phyto" value={formData.medoc_ou_complement_ou_phyto} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Dose" name="dose" value={formData.dose} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Frequence" name="frequence" value={formData.frequence} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Duree Traitement" name="duree_traitement" value={formData.duree_traitement} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Conseil Utilisation" name="conseil_utilisation" value={formData.conseil_utilisation} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Tableau ou Pas" name="tableau_ou_pas" value={formData.tableau_ou_pas} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Age Specifique Medic" name="age_specifique_medic" value={formData.age_specifique_medic} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Min Age Enfant" name="min_age_enfant" value={formData.min_age_enfant} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Sexe Specifique Medic" name="sexe_specifique_medic" value={formData.sexe_specifique_medic} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Femme Enceinte" name="femme_enceinte" value={formData.femme_enceinte} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Allaitement" name="allaitement" value={formData.allaitement} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="HTA" name="hta" value={formData.hta} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Diabete" name="diabete" value={formData.diabete} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Tags" name="tags" value={formData.tags} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Legal Class" name="legal_class" value={formData.legal_class} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Entry Type" name="entry_type" value={formData.entry_type} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Rules" name="rules" value={formData.rules} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Traitement Appoint" name="traitement_appoint" value={formData.traitement_appoint} onChange={handleChange} fullWidth margin="normal" />
      <FormControl fullWidth margin="normal">
        <InputLabel id="pathology-label">Pathology</InputLabel>
        <Select
          labelId="pathology-label"
          name="pathologyId"
          value={formData.pathologyId}
          onChange={handleChange}
        >
          {pathologies.map((pathology) => (
            <MenuItem key={pathology._id} value={pathology._id}>
              {`${pathology.appareilId.appareil} - ${pathology.pathologie}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};

export default MedicamentVariantForm;
