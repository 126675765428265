// AddPathologyForm.jsx
import React from "react";
import "./AddPathologyForm.css"; // Importing CSS for styling

const PathologyCard = ({ isOpen, togglePopup, selectedPathology }) => {
  if (!isOpen || !selectedPathology) return null;

  return (
    <div className="popup-overlay" onClick={togglePopup}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="pathology-details">
          <p>
            <strong>Nom de la pathologie:</strong> {selectedPathology.pathologie}
          </p>
          <p>
            <strong>Appareil:</strong> {selectedPathology.appareilId.appareil}
          </p>
          <p>
            <strong>Age spécifique:</strong>{" "}
            {selectedPathology.age_specifique_pathologie === 'A'? 'Adulte' : selectedPathology.age_specifique_pathologie === 'E'? 'Enfant' : 'unset'}
          </p>
          <p>
            <strong>Sexe spécifique:</strong>{" "}
            {selectedPathology.sexe_specifique_pathologie === 'H'? 'Homme' : selectedPathology.sexe_specifique_pathologie === 'F'? 'Femme' : 'unset'}
          </p>
          <p>
            <strong>Pathologies associées:</strong>{" "}
            {selectedPathology.patho_associées}
          </p>
          <p>
            <strong>Red flags:</strong> {selectedPathology.red_flags}
          </p>
          <p>
            <strong>Green flags:</strong> {selectedPathology.green_flags}
          </p>
          <p>
            <strong>Min Age:</strong> {selectedPathology.min_age_pathologie}
          </p>
          <p>
            <strong>Max Age:</strong> {selectedPathology.max_age_pathologie}
          </p>
          <p>
            <strong>Créé à:</strong>{" "}
            {new Date(selectedPathology.createdAt).toLocaleString('en-GB')}
          </p>
          <p>
            <strong>Dernière mise à jour:</strong>{" "}
            {new Date(selectedPathology.updatedAt).toLocaleString('en-GB')}
          </p>
        </div>
        <div className="button-group">
          <button className="close-popup" onClick={togglePopup}>
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default PathologyCard;
