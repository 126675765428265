import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Sidebar from "../components/Sidebar";
import MedicamentSuggestions from "../pages/MedicamentSuggestions";
import Medicaments from "../pages/Medicaments";
import MedicamentVariantList from "../pages/MedicamentVariantList";
import ReportProblems from "../pages/Problems";
import InteractionList from "../pages/InteractionsList";
import PctMedicamentList from "../pages/PctMedicamentList";
import Pathologies from "../pages/Pathologies";

const Authenticated = () => {
  return (
    
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <div style={{ marginLeft: '260px', width: '100%', padding: '20px' }}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/suggestions" element={<MedicamentSuggestions />} />
          <Route path="/medicaments" element={<Medicaments />} />
          <Route path="/interactions" element={<InteractionList />} />
          <Route path="/pct_meds" element={<PctMedicamentList />} />
          <Route path="/medicaments/:medicamentId/:specialite/variants" element={<MedicamentVariantList />} />
          <Route path="/problems" element={<ReportProblems/>}/>
          <Route path="/pathologies" element={<Pathologies />} />
        </Routes>
        </div>
      </div>
    
  );
};
export default Authenticated;
