import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Pathologies.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddPathologyForm from "../components/AddPathologyForm";
import PathologyCard from "../components/PathologyCard";

const Pathologies = () => {
  const [pathologies, setPathologies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFormPopupOpen, setIsFormPopupOpen] = useState(false);
  const [isCardPopupOpen, setIsCardPopupOpen] = useState(false);
  const [selectedPathology, setSelectedPathology] = useState(null);

  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  useEffect(() => {
    const fetchPathologies = async () => {
      try {
        const response = await axios.get(
          "/pathologies",
          { 
            headers: {
              "x-access-token": token,
            }
          },
        );
        setPathologies(response.data);
      } catch (err) {
        setError("Error fetching pathologies");
      } finally {
        setLoading(false);
      }
    };

    fetchPathologies();
  }, [token, isFormPopupOpen]);

  const toggleFormPopup = () => {
    setIsFormPopupOpen(!isFormPopupOpen);
  };

  const toggleCardPopup = async (id) => {
    try {
      const response = await axios.get(`/pathologies/${id}`, {
        headers: {
          "x-access-token": token,
        },
      });
      setSelectedPathology(response.data);
      setIsCardPopupOpen(true);
    } catch (err) {
      setError("Error fetching pathology details");
    }
  };

  const closeCardPopup = () => {
    setIsCardPopupOpen(false);
    setSelectedPathology(null); // Clear selected pathology on close
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="table-container">
      <div className="header">
        <h2 className="table-title">Pathologies Table</h2>
        <button className="add-button" onClick={toggleFormPopup}>
          <span className="ajouter-une-pathologie-text">
            Ajouter une pathologie
          </span>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
      <table className="pathologies-table">
        <thead>
          <tr>
            <th>Pathologie</th>
            <th>Appareil</th>
          </tr>
        </thead>
        <tbody>
          {pathologies.map((pathologie) => (
            <tr key={pathologie._id} onClick={() => toggleCardPopup(pathologie._id)}>
              <td>{pathologie.pathologie}</td>
              <td>{pathologie.appareilId.appareil}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <PathologyCard
        isOpen={isCardPopupOpen}
        togglePopup={closeCardPopup}
        selectedPathology={selectedPathology}
      />
      <AddPathologyForm
        isOpen={isFormPopupOpen}
        togglePopup={toggleFormPopup}
      />
    </div>
  );
};

export default Pathologies;
