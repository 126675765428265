import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Tooltip,
  TextField,
  TablePagination,
} from '@mui/material';
import MedicamentForm from './MedicamentForm';
import { useNavigate } from 'react-router-dom';

const MedicamentList = () => {
  const [medicaments, setMedicaments] = useState([]);
  const [filteredMedicaments, setFilteredMedicaments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentMedicament, setCurrentMedicament] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchMedicaments();
  }, []);

  useEffect(() => {
    filterMedicaments();
  }, [searchQuery, medicaments]);

  const fetchMedicaments = async () => {
    try {
      const response = await axios.get("get_all_medicines", {
        headers: { "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token')},
      });
      setMedicaments(response.data);
      setFilteredMedicaments(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const filterMedicaments = () => {
    if (!searchQuery) {
      setFilteredMedicaments(medicaments);
      return;
    }

    const filtered = medicaments.filter((med) =>
      (med.dci && med.dci.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (med.specialite && med.specialite.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (med.forme && med.forme.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    setFilteredMedicaments(filtered);
  };

  const handleAddClick = () => {
    setCurrentMedicament(null);
    setOpen(true);
  };

  const handleUpdateClick = (medicament) => {
    setCurrentMedicament(medicament);
    setOpen(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`medicament/${id}`, {
        headers: { "x-access-token": localStorage.getItem("token") || sessionStorage.getItem('token')},
      });
      fetchMedicaments();
    } catch (error) {
      console.error('Error deleting medicament:', error);
    }
  };

  const handleVariantsClick = (medicamentId, specialite) => {
    navigate(`/medicaments/${medicamentId}/${specialite}/variants`);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentMedicament(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset page to 0 when search query changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box padding={2}>
      <Typography variant={isMobile ? "h4" : "h3"} gutterBottom>
        Medicaments
      </Typography>
      <Button variant="contained" color="primary" onClick={handleAddClick} sx={{ mb: 2 }}>
        Add Medicament
      </Button>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearchChange}
      />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: theme.palette.grey[200] }}>
              <TableRow>
                <TableCell sx={{ maxWidth: isMobile ? '100px' : '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  DCI
                </TableCell>
                <TableCell>Specialite</TableCell>
                <TableCell>Forme</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredMedicaments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((med) => (
                  <TableRow key={med._id}>
                    <TableCell sx={{ maxWidth: isMobile ? '100px' : '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      <Tooltip title={med.dci}>
                        <span>{med.dci}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word', padding: isMobile ? '6px' : 'default' }}>
                      {med.specialite}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word', padding: isMobile ? '6px' : 'default' }}>
                      {med.forme}
                    </TableCell>
                    <TableCell align="right" sx={{ padding: isMobile ? '6px' : 'default' }}>
                      <ButtonGroup variant="outlined" size="small">
                        <Button onClick={() => handleUpdateClick(med)} color="primary">
                          Update
                        </Button>
                        <Button onClick={() => handleVariantsClick(med._id, med.specialite)} color="secondary">
                          View Variants
                        </Button>
                        <Button onClick={() => handleDeleteClick(med._id)} color="error">
                          Delete
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredMedicaments.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </TableContainer>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{currentMedicament ? 'Update Medicament' : 'Add Medicament'}</DialogTitle>
        <DialogContent>
          <MedicamentForm
            medicament={currentMedicament}
            onClose={handleClose}
            onSave={fetchMedicaments}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MedicamentList;
