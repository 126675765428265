import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ReportProblems = () => {
    const [problems, setProblems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchProblems();
    }, []);

    const fetchProblems = async () => {
        try {
            const response = await axios.get("/problems", {
                headers: {
                    "x-access-token": localStorage.getItem("token") || sessionStorage.getItem("token"),
                },
            });
            setProblems(response.data);
            setLoading(false);
        } catch (error) {
            setError('Error fetching problems. Please try again later.');
            setLoading(false);
        }
    };

    const handleCheckboxChange = async (problemId) => {
        try {
            const updatedProblems = problems.map(problem => {
                if (problem._id === problemId) {
                    return { ...problem, isFixed: !problem.isFixed };
                }
                return problem;
            });
    
            setProblems(updatedProblems);
    
            await axios.put(`/problem/${problemId}`, { isFixed: updatedProblems.find(problem => problem._id === problemId).isFixed }, {
                headers: {
                    "x-access-token": localStorage.getItem("token") || sessionStorage.getItem("token"),
                },
            });
        } catch (error) {
            console.error('Error marking problem as fixed:', error);
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <h1>Report Problems</h1>
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {!loading && !error && (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', fontWeight: 'bold', width: '20%' }}>User Name</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', fontWeight: 'bold' }}>Problem Report</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', fontWeight: 'bold' }}>Timestamp</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2', fontWeight: 'bold' }}>Fixed</th>
                        </tr>
                    </thead>
                    <tbody>
                    {problems.slice().reverse().map(problem => (
                            <tr key={problem._id}>
                                <td style={{ border: '1px solid #ddd', padding: '8px' , width: '20%'}}>{problem.user ? problem.user.username : 'Unknown User'}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{problem.problem}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{new Date(problem.created_at).toLocaleString()}</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                    <input
                                        type="checkbox"
                                        checked={problem.isFixed}
                                        onChange={() => handleCheckboxChange(problem._id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default ReportProblems;
