import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Grid } from '@mui/material';
import axios from 'axios';

axios.defaults.baseURL = 'https://mvp.saydalid.com/api/';

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
  
    // Dummy check
    async function handleLogin() {
      try {
        const response = await axios.post("/auth/signin", {
          email: username,  // Assuming username is actually the email here
          password: password
        });
    
        if (response.data.roles.includes("admin")) {
          sessionStorage.setItem("token", response.data.accessToken);
          window.location.href = "/dashboard"
        } else {
          alert("You are not an admin!");
        }
    
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          alert("Failed to login. Please check your credentials.");
        } else if (error.request) {
          // The request was made but no response was received
          alert("No response from the server. Please try again later.");
        } else {
          // Something happened in setting up the request that triggered an Error
          alert("Error in sending request.");
        }
      }
    }
  
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        height="100vh"
      >
        <Box 
          component={Grid} 
          container 
          direction="column" 
          spacing={2}
          style={{ maxWidth: '300px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}
        >
          <Grid item>
            <Typography variant="h4">Login</Typography>
          </Grid>
          <Grid item>
            <TextField 
              fullWidth 
              label="Username" 
              value={username} 
              onChange={e => setUsername(e.target.value)} 
            />
          </Grid>
          <Grid item>
            <TextField 
              fullWidth 
              type="password" 
              label="Password" 
              value={password} 
              onChange={e => setPassword(e.target.value)} 
            />
          </Grid>
          <Grid item>
            <Button 
              fullWidth 
              variant="contained" 
              color="primary" 
              onClick={handleLogin}
            >
              Login
            </Button>
          </Grid>
        </Box>
      </Box>
    );
  }

  export default Login;